import {Modal, Box, TextField, FormControlLabel, Button, Checkbox} from "@mui/material";
import {TitleModal, ContainerButtons} from "./style";
import {Close, Delete} from "@mui/icons-material";
import {useEffect, useState} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadIcon from "@mui/icons-material/Upload";
import styled from "@emotion/styled";
import ProgressBar from "../ProgressBar/ProgressBar";


const ModalUpload = ({openModalUpload=false, onClose, modalTitle, file, handleUpload, setFile, handleFile, progress, applyAll = false}) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    maxWidth: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
  };

  return (
    <Modal open={openModalUpload} onClose={onClose}>
      <Box sx={style}>
        <TitleModal>
          <p>{modalTitle}</p> <Close onClick={onClose} />
        </TitleModal>

        {file == null && (
          <Button component="label" variant="contained" startIcon={<UploadIcon />} sx={{width: "fit-content"}} color="secondary">
            INSERIR ARQUIVO <VisuallyHiddenInput onInput={handleFile} type="file" />
          </Button>
        )}

        {file && (
          <p style={{display: "flex", alignItems: "center", gap: "5px"}}>
            Arquivo: {file.name}{" "}
            <Delete
              onClick={() => {
                setFile(null);
              }}
              fontSize="small"
            />{" "}
          </p>
        )}

        {applyAll && <FormControlLabel sx={{width: "100%"}} control={<Checkbox />} label="Aplicar para todas as agências." />}

        {progress > 0 && <ProgressBar fullWidth value={progress} />}
        {progress == 0 && (
          <Button className="main-btn" fullWidth onClick={handleUpload}  variant="contained">
            enviar
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default ModalUpload;
