import React, {useEffect, useState, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {MenuComp, Divider} from "./style";
import {Squash as Hamburger} from "hamburger-react";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "./MenuItem";
import {Avatar} from "@mui/material";
import makeRequest from "../../utils/makeRequest";
import {deepPurple} from "@mui/material/colors";
import getCsrfToken from "../../utils/getCsrfToken";
import SelectInput from "../../Budget/components/SelectInput/SelectInput";
import {WebSocketContext} from "../../Budget/service/WSContext";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import list from "../../utils/list_agencies.json";
import useAgencyStore from "../../Budget/service/AgencyStore";
import setCookie from "../../utils/setCookie";
import getCookie from "../../utils/GetCookie";
import { Switch, useLocation } from 'react-router-dom'

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});




const MenuLateral = ({currentPage, open, env, setOpen}) => {


  function usePageViews() {
    let location = useLocation()

    useEffect(() => {
      //console.log('pageview', location.pathname);
      sendMessageWS({method: "change_location",location:location.pathname});
    }, [location])
  }
  usePageViews();



  const navigate = useNavigate();
  // const [agencies, setAgencies] = useState([...list.data.agencies]);
  const [agencies, setAgencies] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const agency_uuid = useAgencyStore(state => state.agency_uuid);
  const updateAgency = useAgencyStore(state => state.updateAgency);

  const setGlobalAgencyUUID = uuid => {
    updateAgency(uuid);
    setSelectedAgency(uuid);
    setCookie("agencyuuid", uuid, 7);
  };

  const handleInput = e => {
    setGlobalAgencyUUID(e.target.value);
    window.location.reload()
  };

  const handleLogout = () => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/logout_user/`;

    getCsrfToken().then(res => {
      makeRequest(url, "", "get", "", res.data).then(response => {
        if (response.status == 200) {
          navigate("/login");
        }
      });
    });
  };

  useEffect(() => {
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  useEffect(() => {
    sendMessageWS({method: "list_agencies"});
  }, []);

  useEffect(() => {
    var uuidCookie = getCookie("agencyuuid");
    if (uuidCookie) {
      setSelectedAgency(uuidCookie);
    }
    if (agencies && selectedAgency == false && !getCookie("agencyuuid")) {
      setGlobalAgencyUUID(agencies[0].uuid)
    }
  }, [agencies]);

  return (
    <ThemeProvider theme={darkTheme}>
      <MenuComp env={env} className={open ? `open` : ``}>
        {window.innerWidth < 800 && <Hamburger toggled={open} toggle={setOpen} color={open ? "#fff" : "#000"} />}

        <h3>
          <span>smart</span>
          <b>budget</b>
        </h3>

        <div className="user-info">
          <Avatar sx={{bgcolor: deepPurple[500], width: 30, height: 30, fontSize: 15}}>
            {" "}
            {window.sessionStorage.getItem("initials") && window.sessionStorage.getItem("initials").toUpperCase()}{" "}
          </Avatar>
          <a className="login-link" onClick={handleLogout}>
            Sair
          </a>
        </div>

        <Divider />
        <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/orcamentos"} label={"Orçamentos"} />
        <Divider />
        <div className="menu-subitems">
          <SelectInput
            name="agency_uuid"
            onChange={handleInput}
            id="outlined-basic"
            sx={{flexGrow: 1, marginBottom: 1}}
            size="small"
            variant="outlined"
            label="Agência"
            value={selectedAgency}
            options={agencies ? agencies : []}
          />
          <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/clientes"} label={"Clientes"} />
          <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/cargos"} label={"Cargos"} style={{display: window.sessionStorage.getItem("sysaccess") == '4' ? 'block' : 'none' }} />
          <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/fornecedores"} label={"Fornecedores"} />
          <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/servicos"} label={"Serviços"} />
          <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/clausulas"} label={"Cláusulas"} />

          {/* <MenuItem type="menu-subitem" setOpen={setOpen} path={"/budgets/relatorios"} label={"Relatórios"} /> */}
        </div>

        <div className="menu-subitems settings">
          <Divider />
          <MenuItem
            type="menu-subitem"
            setOpen={setOpen}
            style={{gap: "5px", marginLeft: "-2px"}}
            path={"configuracoes"}
            label={"Configurações"}
            startIcon={<SettingsIcon fontSize="small" />}
          />
        </div>
      </MenuComp>
    </ThemeProvider>
  );
};

export default MenuLateral;
